import React, { useState, useEffect } from 'react';
import './App.css';
import Stopwatch from './components/Stopwatch';

function parseJson() {
  try {
    return JSON.parse(localStorage.getItem('timers') ?? []);
  } catch (ex) {
    return [];
  }
}

function App() {
  const [timers, setTimers] = useState(parseJson());
  const [warning, setWarning] = useState('');
  const [inputField, setInputField] = useState('');

  useEffect(() => {
    localStorage.setItem('timers', JSON.stringify(timers));

    return () => {
      localStorage.setItem('timers', JSON.stringify(timers));
    };
  }, [timers]);

  return (
    <div className="flex flex-col items-center m-5">
      <h1 className="text-3xl font-bold text-center">
        Keep track of individual timers
      </h1>
      <div className="flex flex-row pr-5 content-center">
        <div className="flex rounded-full bg-white">
          <button
            type="button"
            className="mr-2.5 ml-1 m-auto"
            onClick={(event) => {
              event.preventDefault();
              const newTimers = timers ? [...timers] : [];
              if (newTimers?.indexOf(inputField) !== -1) {
                setWarning('This timer already exists');
                return;
              }
              newTimers.push(inputField);
              setTimers(newTimers);
              setInputField('');
              setWarning('');
            }}
          >+
          </button>
          <input type="text" value={inputField} onChange={(event) => setInputField(event.target.value)} placeholder="New timer" className="pl-2 border-2 w-40 border-black rounded" />
        </div>
        <span className="pl-1">{warning}</span>
      </div>
      <div className="flex flex-row flex-wrap justify-center">
        {timers?.map((el) => (
          <Stopwatch name={el} setTimers={setTimers} />))}
      </div>
    </div>
  );
}

export default App;
