import React, { useEffect, useState } from 'react';
import { BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs';
import { VscDebugRestart } from 'react-icons/vsc';

const Stopwatch = ({ name, setTimers }) => {
  const [time, setTime] = useState(parseInt(localStorage.getItem(name), 10) || 0);
  const [timerOn, setTimerOn] = useState(false);

  useEffect(() => {
    let interval = null;
    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    localStorage.setItem(name, time);

    return () => {
      clearInterval(interval);
      localStorage.removeItem(name);
    };
  }, [timerOn]);

  return (
    <div className="rounded bg-gray-200 flex m-2 flex-col w-40">
      <div className="flex flex-row m-auto mt-1 w-full px-2">
        <div className="mr-auto" visibility="hidden" />
        <div className="font-bold text-xl">{name}</div>
        <button
          type="button"
          className="ml-auto -mt-1.5 font-bold pt-full"
          onClick={() => setTimers((prevValue) => prevValue.filter((el) => el !== name))}
        >x
        </button>
      </div>
      {/* Timer Module */}
      <div className="flex items-center flex-col mb-2">
        <div className="sm:text-2xl lg:text-3xl m-1">
          <span>{time > 3599 ? `${Math.floor((time / 3600))}:`.slice(-3) : ''}</span>
          <span>{time > 59 ? `0${Math.floor((time / 60) % 60)}:`.slice(-3) : ''}</span>
          <span>{`0${time % 60}`.slice(-2)}</span>
        </div>
        <div className="flex flex-row">
          {timerOn ? <BsFillPauseFill size={30} className="rounded hover:bg-gray-400" onClick={() => setTimerOn(false)} /> : <BsFillPlayFill size={30} className="rounded hover:bg-gray-400" onClick={() => setTimerOn(true)} />}
          <VscDebugRestart size={30} className="rounded hover:bg-gray-400" onClick={() => setTime(0)} />
        </div>
      </div>
    </div>
  );
};
export default Stopwatch;
